import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import {
  IdentityVerificationMethodType,
  Gender,
  IdentitySignInByPasswordInput,
  IdentityVerifyType,
  BaseUserStatus,
  BaseUserStatuses,
  IdentityPreVerifyInput,
  IdentityPreVerifyType,
  NebulaeIdentityService,
  NebulaeLoyaltyService,
  IdentitySignUpByPasswordInput,
} from '@nebulaeapp/nebulae-sdk-angular';
import { AuthService } from '../../services/auth.service';

// const TOKEN_KEY = 'my-token';
const PROGRAM_ID = environment.program_id;

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  constructor(
    private identity: NebulaeIdentityService,
    private loyalty: NebulaeLoyaltyService,
    public auth: AuthService
  ) {}

  // Request Signup Verification
  async requestSignUpVerification(data): Promise<any> {
    try {
      const result = await this.identity.auth.requestSignupVerification({
        phone: {
          country_code: data.country_code.substring(1),
          phone_number: data.phone,
        },
        email: data.email,
        verification_method: IdentityVerificationMethodType.SMS_OTP,
      });

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  // Resend Signup Verification
  async resendSignUpVerification(phone, lang): Promise<any> {
    try {
      const result = await this.identity.auth.resendSignupVerification({
        phone,
        lang,
      });

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //verify signup otp
  async signupVerificationCheck(data): Promise<any> {
    try {
      const result = await this.identity.auth.signupVerificationCheck({
        verification_method: IdentityVerificationMethodType.SMS_OTP,
        phone: {
          country_code: data.country_code.substring(1),
          phone_number: data.phone,
        },
        email: data.email,
        code: data.code,
      });
      await this.auth.loadToken();

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  //verify signpup data
  async signup(data, otp): Promise<any> {
    let dob: any = {};
    if (data.dob) {
      dob.year = parseInt(moment(data.dob).format('YYYY'));
      dob.month = parseInt(moment(data.dob).format('MM'));
      dob.day = parseInt(moment(data.dob).format('DD'));
    }

    try {
      let basic_info: any = {
        firstname: data.first_name,
        lastname: data.last_name,
        phone: data.phone,
        born_on: dob,
        email: data.email,
        password: data.password,
        verification_method: IdentityVerificationMethodType.SMS_OTP,
        verification_code: otp,
        referrer_code: data.referrer_code,
        preferred_language: data.preferred_language,
      };
      if (data.phone.country_code !== '852') {
        basic_info.verification_method =
          IdentityVerificationMethodType.EMAIL_OTP;
      }
      basic_info.attributes = {};

      if (data.skin) {
        const skin_key = data.skin;
        const lower_skin_key = skin_key.toLowerCase();
        const new_skin_key = lower_skin_key.replace(/ /g, '_');
        basic_info.attributes.SKIN_TYPE = new_skin_key;
      }

      // if(data.marketing_type.includes('Facebook')){
      //   basic_info.attributes.MARKETING_OPT_OUT_FACEBOOK = true;
      // }
      // if(data.marketing_type.includes('Google')){
      //   basic_info.attributes.MARKETING_OPT_OUT_GOOGLE = true;
      // }
      // if(data.marketing_type.includes('Instagram')){
      //   basic_info.attributes.MARKETING_OPT_OUT_INSTAGRAM = true;
      // }
      basic_info.attributes.MARKETING_OPT_OUT = data.marketing_opt_out;

      if (data.gender) {
        basic_info.gender = data.gender;
      }
      if (data.channel) {
        basic_info.attributes.channel = data.channel.toLowerCase();
      }
      if (data.content) {
        basic_info.attributes.content = data.content.toLowerCase();
      }
      if (data.campaign) {
        basic_info.attributes.campaign = data.campaign.toLowerCase();
      }

      console.log('basic_info::', basic_info);

      const result = await this.identity.auth.signupWithEmailAndPassword({
        basic_info,
      });
      return Promise.resolve(result);
    } catch (error: any) {
      return Promise.reject(error.body?.error ?? error.body ?? error);
      // if (error.body && error.body.error) {
      //   return Promise.reject(error.body.error.message);
      // } else {
      //   return Promise.reject(error);
      // }
    }
  }
}
