import { Injectable } from "@angular/core";
// import { Storage } from "@ionic/storage-angular";
import { environment } from "../../environments/environment";
import {
  IdentityVerificationMethodType,
  NebulaeIdentityService,
  NebulaeLoyaltyMemberService,
} from "@nebulaeapp/nebulae-sdk-angular";

// import { HttpClient, HttpParams } from '@angular/common/http';
// import { TranslateService } from "@ngx-translate/core";
// import * as moment from "moment";;

// interface Phone {
//   country_code: number,
//   phone_number: number
// }

@Injectable({
  providedIn: "root",
})
export class ApiService {

  // program_id = "p_cXIvNlxBqVpRFhAVIjatcb0MqP9LBIfpB2H"
  // client_id = "cU8arbPhc5PcuwdCUsHbVwmHKJldCPfH1yQ"
  // baseUrl = 'https://apigw-ap-northeast-1.cmloyalty-dev.nebulaeapp.com/identity'
  // loyaltyUrl = 'https://apigw-ap-northeast-1.cmloyalty-dev.nebulaeapp.com/loyalty'

  constructor(
    private identity: NebulaeIdentityService,
    private loyaltymember: NebulaeLoyaltyMemberService,
    // private http: HttpClient,
    // private translate: TranslateService,
    // public storage: Storage,
  ) {}


  // async checkUserExists(phone: Phone) {
  //   const payload = {
  //     client_id: this.client_id,
  //     phone,
  //   }
  //   const result: any = await this.http.post(`${this.baseUrl}/v2/auth/check_user_exists`, payload).toPromise()
  //   console.log('result', result)
  //   return result.data
  // }

  // async requestSignUpVerification(phone: Phone) {
  //   this.translate.currentLang

  //   const payload = {
  //     client_id: this.client_id,
  //     phone,
  //     verification_method: "sms_otp",
  //     lang: this.translate.currentLang,
  //   }

  //   return await this.http.post(`${this.baseUrl}/v2/auth/request_verification`,
  //     payload
  //   ).toPromise();

  // }

  // async requestSignInOtp(phone: Phone) {

  //   const payload = {
  //     client_id: this.client_id,
  //     phone,
  //     verification_method: "sms_otp",
  //     lang: this.translate.currentLang,
  //   }

  //   return await this.http.post(`${this.baseUrl}/v2/auth/request_signin_otp`,
  //     payload
  //   ).toPromise();
  // }

  // async passwordlessSignin(data: {
  //   phone: {
  //     country_code: string,
  //     phone: string
  //   },
  //   code: string
  // }): Promise<any> {
  //   throw new Error('function not impletement')
  // }

  // async signup(data: any, otp) {

  //   // ---------------------------------------------------

  //   let dob: any = {};
  //   if (data.dob) {
  //     dob.year = moment(data.dob).format("YYYY");
  //     dob.month = moment(data.dob).format("MM");
  //     dob.day = moment(data.dob).format("DD");
  //   }

  //   let basic_info: any = {
  //     firstname: data.first_name,
  //     lastname: data.last_name,
  //     phone: data.phone,
  //     born_on: dob,
  //     email: data.email,
  //     password: data.password,
  //     verification_method: IdentityVerificationMethodType.SMS_OTP,
  //     verification_code: otp,
  //     referrer_code: data.referrer_code,
  //     preferred_language: data.preferred_language,
  //   };
  //   basic_info.attributes = {};

  //   if (data.skin) {
  //     const skin_key = data.skin;
  //     const lower_skin_key = skin_key.toLowerCase()
  //     const new_skin_key = lower_skin_key.replace(/ /g, "_");
  //     basic_info.attributes.SKIN_TYPE = new_skin_key;
  //   }

  //   basic_info.attributes.MARKETING_OPT_OUT = data.marketing_opt_out

  //   if (data.gender) {
  //     basic_info.gender = data.gender;
  //   }
  //   if (data.channel) {
  //     basic_info.attributes.channel = data.channel.toLowerCase();
  //   }
  //   if (data.content) {
  //     basic_info.attributes.content = data.content.toLowerCase();
  //   }
  //   if (data.campaign) {
  //     basic_info.attributes.campaign = data.campaign.toLowerCase();
  //   }

  //   // ---------------------------------------------------

  //   const payload = {
  //     ...basic_info,
  //     preferred_language: this.translate.currentLang,
  //     client_id: this.client_id,
  //   }
  //   return await this.http.post(`${this.baseUrl}/v2/auth/signup`,
  //     payload
  //   ).toPromise();
  // }

  // async signin(data) {

  //   const payload = {
  //     email: data.email,
  //     password: data.password,
  //     client_id: this.client_id
  //   }

  //   const siginResult: any = await this.http.post(`${this.baseUrl}/v2/auth/signin`,
  //     payload
  //   ).toPromise();

  //   if (siginResult) {
  //     await this.exchangeToken(siginResult.data.code)
  //   }

  // }

  // async verify(phone: Phone) {

  //   const payload = {
  //     client_id: this.client_id,
  //     phone,
  //     type: "signin",
  //     verification_method: "sms_otp",
  //   }

  //   const verifyResult: any = await this.http.post(`${this.baseUrl}/v2/auth/verify`,
  //     payload
  //   ).toPromise();

  //   if (verifyResult) {
  //     await this.exchangeToken(verifyResult.data.code)
  //   }

  // }

  // async exchangeToken(code) {

  //   const formData = new HttpParams()
  //     .set('client_id', this.client_id)
  //     .set('code', code)
  //     .set('grant_type', 'authorization_code');

  //   const tokenResult: any = await this.http.post(`${this.baseUrl}/v2/auth/token`,
  //     formData.toString(),
  //     {
  //       headers: {
  //         'Content-Type': 'application/x-www-form-urlencoded'
  //       }
  //     }
  //   ).toPromise();

  //   console.log('token', tokenResult)

  //   if (tokenResult) {
  //     await this.storage.set('access_token', tokenResult.data?.access_token)
  //   }

  // }

  // async getMemberProfile() {
  //   const access_token = await this.storage.get('access_token')
  //   return this.http.get(`${this.loyaltyUrl}/v2/member/profile`, {
  //     headers: {
  //       'authorization': `Bearer ${access_token}`
  //     }
  //   }).toPromise();
  // }

  // async getNewsList() {
  //   let params: HttpParams = new HttpParams()
  //     .append('program_id', this.program_id)
  //     .append('type', 'news')
  //     .append('sort', JSON.stringify({ ordering: "asc" }));
  //   return this.http.get(`${this.loyaltyUrl}/v2/news`, { params: params }).toPromise()
  // }

  async getHouseList(limit, skip) {
    try {
      const result = await this.loyaltymember.news.list({
        program_id: environment.program_id,
        type: "story" as any,
        sort: { promotion_order: "asc" } as any,
        limit: limit,
        skip: skip
      });

      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  async getHouseDetail(id) {
    try {
      const result = await this.loyaltymember.news.retrieve(id);
      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }

  async getStores() {
    try {
      const result = await this.loyaltymember.stores.list({
        visible: true,
        program_id: environment.program_id,
        limit: 100,
      });
      return Promise.resolve(result);
    } catch (error: any) {
      if (error.body && error.body.error) {
        return Promise.reject(error.body.error.message);
      } else {
        return Promise.reject(error);
      }
    }
  }
}
